import * as React from "react"
const GithubIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#2E2E2E"
      fillRule="evenodd"
      d="M7.69.36A7.688 7.688 0 0 0 0 8.05a7.685 7.685 0 0 0 5.258 7.295c.384.067.529-.164.529-.366 0-.182-.01-.788-.01-1.432-1.932.356-2.432-.47-2.586-.903-.086-.221-.461-.904-.788-1.087-.27-.144-.654-.5-.01-.509.606-.01 1.039.558 1.183.788.692 1.163 1.797.836 2.24.635.067-.5.268-.837.49-1.029-1.711-.192-3.5-.855-3.5-3.797 0-.836.299-1.528.789-2.066-.077-.192-.346-.98.077-2.038 0 0 .644-.202 2.115.788a7.136 7.136 0 0 1 1.922-.26c.654 0 1.307.087 1.922.26 1.471-1 2.115-.788 2.115-.788.423 1.057.154 1.846.077 2.038.49.538.788 1.22.788 2.066 0 2.951-1.797 3.605-3.508 3.797.278.24.519.702.519 1.423 0 1.028-.01 1.855-.01 2.114 0 .202.144.443.529.366a7.703 7.703 0 0 0 5.238-7.296A7.688 7.688 0 0 0 7.69.36Z"
      clipRule="evenodd"
    />
  </svg>
)
export default GithubIcon
